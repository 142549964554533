import React, { useContext } from 'react';
import { ethers } from 'ethers';
import { NameServiceInterface__factory } from '../typechain';
import { useQuery } from '@tanstack/react-query';
import { bytes32ToString } from '../utils/utils';
type NsKeys = 'SafetyBoxLogic' | 'Manager' | 'AggregateCall' | 'ppe' | 'usdt';
type NsRecords = Record<NsKeys, string>;
type NameserviceContextType = NsRecords | null;

const NameserviceContext = React.createContext<NameserviceContextType>(null);

export const useNameservice = () => useContext(NameserviceContext);

const NameserviceProvider = ({ children }: { children: React.ReactNode }) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const nsAddress = process.env.REACT_APP_NS!;
    const nsService = NameServiceInterface__factory.connect(nsAddress, provider.getSigner());

    const { isSuccess, data: queryResult } = useQuery({
      queryKey: ['nsQuery'],
      queryFn: () => {
        return nsService.listSingleEntries();
      }
    });

    let addresses: NsRecords = null;
    if (isSuccess) {
      addresses = {} as NsRecords;
      queryResult?.forEach((item) => {
        addresses[bytes32ToString(item.name)] = item.record.addr;
      });
    }
    return <NameserviceContext.Provider value={addresses}>{children}</NameserviceContext.Provider>;
  } catch (error) {
    return <NameserviceContext.Provider value={null}>{children}</NameserviceContext.Provider>;
  }
};

export default NameserviceProvider;
