/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  GenericNameServiceRefLogic,
  GenericNameServiceRefLogicInterface,
} from "../../../../contracts/dependant/nameServiceRef/GenericNameServiceRefLogic";

const _abi = [
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b50608280601d6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c806313007d5514602d575b600080fd5b600054604080516001600160a01b039092168252519081900360200190f3fea264697066735822122070474b89c2ec3cb13a6edc422a9b766ccc798d331256d94adbe19726a477f3d264736f6c63430008110033";

type GenericNameServiceRefLogicConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: GenericNameServiceRefLogicConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class GenericNameServiceRefLogic__factory extends ContractFactory {
  constructor(...args: GenericNameServiceRefLogicConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<GenericNameServiceRefLogic> {
    return super.deploy(overrides || {}) as Promise<GenericNameServiceRefLogic>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): GenericNameServiceRefLogic {
    return super.attach(address) as GenericNameServiceRefLogic;
  }
  override connect(signer: Signer): GenericNameServiceRefLogic__factory {
    return super.connect(signer) as GenericNameServiceRefLogic__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): GenericNameServiceRefLogicInterface {
    return new utils.Interface(_abi) as GenericNameServiceRefLogicInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): GenericNameServiceRefLogic {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as GenericNameServiceRefLogic;
  }
}
