import React from 'react';
import { useAccount, useBalance, useConnect, useDisconnect } from 'wagmi';
import { bsc } from 'wagmi/chains';
// import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { InjectedConnector, type FetchBalanceResult } from '@wagmi/core';

interface WalletContextType {
  accountAddress: `0x${string}` | undefined;
  isConnected: boolean;
  nativeBalance: FetchBalanceResult | undefined;
  connect: any;
  disconnect: any;
}

const WalletContext = React.createContext<WalletContextType>({
  accountAddress: undefined,
  isConnected: false,
  nativeBalance: undefined,
  connect: () => {},
  disconnect: () => {}
});

export const useWalletLegacy = () => React.useContext(WalletContext);

const useProviderWallet = () => {
  const { address: accountAddress } = useAccount();
  const { data: nativeBalance } = useBalance({
    address: accountAddress,
    chainId: bsc.id,
    watch: true
  });
  const { connectAsync: connect, isSuccess } = useConnect({
    // connector: walletConnectConnector,
    connector: new InjectedConnector({ chains: [bsc] }),
    chainId: bsc.id
  });
  const { disconnect } = useDisconnect();
  return { accountAddress, isConnected: !!accountAddress, nativeBalance, connect, disconnect };
};

export const useWallet = useProviderWallet;

const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const wallet = useProviderWallet();
  return <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>;
};
export default WalletProvider;
